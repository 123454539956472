export default {
  data() {
    return {
      leavingRoute: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.leavingRoute = true;
    this.$refs.editor.handleRouteLeave(to, from, next);
  }
};
