<template>
  <div class="validation-errors">
    <div
      v-for="(error, index) in errors"
      :key="index"
      :class="`is-${getType(error)}`"
      class="validation-error-item"
      v-html="getLabel(error)"
    />
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => []
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    parentFormItemStyle() {
      if (!this.showErrors) {
        return ``;
      }
      const totalErrors = this.errors.length;
      return `margin-bottom: ${totalErrors * 22 + 11}px;`;
    }
  },
  watch: {
    parentFormItemStyle() {
      this.$emit('style', this.parentFormItemStyle);
    }
  },
  methods: {
    getLabel(error) {
      if (typeof error === 'string') {
        return error;
      } else if (error.message) {
        return error.message;
      }
      throw new Error('Error object not valid');
    },
    getType(error) {
      if (error && error.type) {
        return error.type;
      }
      return 'error';
    }
  }
};
</script>

<style lang="scss">
.validation-errors {
  display: block;
  color: #f56c6c;
  text-align: left;
  top: 100%;
  .validation-error-item {
    padding-top: 4px;
    font-size: 12px;
    line-height: 16px;
    &.is-warning {
      color: #ec9026;
    }
  }
}
</style>
