export function handleDatabaseRequestError(vue, error, requestErrorHooks, preventGeneralSaveErrorNotifications) {
  const { $store, $i18n } = vue;
  const inputErrors = getInputErrorsMap(error, $store, $i18n);
  console.log(inputErrors, inputErrors.length, error);
  if (isDetailedRequestError(error)) {
    // Handle Request error hooks (components)
    getErrorDetails(error).forEach(err => {
      const errCode = getErrorCode(err);
      const errMessage = getErrorMessage(err, $i18n);

      const hooks = requestErrorHooks && [
        ...ensureArray(requestErrorHooks[errCode]),
        ...ensureArray(requestErrorHooks[null])
      ];
      ensureArray(hooks).forEach(hook => hook(errCode, errMessage));
    });

    // Handle Input errors
    if (Object.keys(inputErrors).length > 0) {
      $store.dispatch('notify/error', { message: $i18n.t('error.oneOrMoreInputs') });
    }
  } else if (!preventGeneralSaveErrorNotifications) {
    // Handle error not related to request
    $store.dispatch('notify/dataSaveError');
  }
  return inputErrors;
}

function getInputErrorsMap(error, store, i18n) {
  const { validationErrorInputPrefix } = store.getters;
  const errors = {};
  getErrorDetails(error).forEach(errorDetail => {
    const errCode = getErrorCode(errorDetail);
    if (errCode && errCode.startsWith(validationErrorInputPrefix)) {
      const field = getErrorField(errCode, validationErrorInputPrefix);
      errors[field] = [getErrorMessage(errorDetail, i18n)];
    }
  });
  return errors;
}

export function isDetailedRequestError(error) {
  return !!(error && error.response && error.response.data && Array.isArray(error.response.data));
}

function getErrorDetails(error) {
  const responseData = error && error.response && error.response.data;
  return ensureArray(responseData);
}

function getErrorField(errorCode, prefix) {
  return errorCode.substring(prefix.length).replace(/\..*$/, '');
}

function getErrorCode(errorDetail) {
  return errorDetail.code || errorDetail.Code;
}

function getErrorMessage(errorDetail, i18n) {
  return errorDetail.message || errorDetail.Message || i18n.t(getErrorCode(errorDetail));
}

function ensureArray(arr) {
  return Array.isArray(arr) ? arr : [];
}
